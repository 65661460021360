const namespace = 'search';

export const SEARCH_SET = `${namespace}/Set`;
export const SEARCH_RESET = `${namespace}/Reset`;

export const SEARCH_SET_PROPERTY = `${namespace}/Set Property`;
export const SEARCH_RESET_PROPERTY = `${namespace}/Reset Property`;

export const SEARCH_DATA = `${namespace}/Data`;
export const SEARCH_DATA_SUCCESS = `${namespace}/Data Success`;
export const SEARCH_DATA_FAIL = `${namespace}/Data Fail`;

export const SEARCH_SINGLE = `${namespace}/Single`;
export const SEARCH_SINGLE_SUCCESS = `${namespace}/Single Success`;
export const SEARCH_SINGLE_FAIL = `${namespace}/Single Fail`;

export const SEARCH_SINGLE_BY_SLUG = `${namespace}/Single By Slug`;
export const SEARCH_SINGLE_BY_SLUG_SUCCESS = `${namespace}/Single By Slug Success`;
export const SEARCH_SINGLE_BY_SLUG_FAIL = `${namespace}/Single By Slug Fail`;

export const SEARCH_COMBINATIONS = `${namespace}/Combinations`;
export const SEARCH_COMBINATIONS_SUCCESS = `${namespace}/Combinations Success`;
export const SEARCH_COMBINATIONS_FAIL = `${namespace}/Combinations Fail`;
