const namespace = 'data';

export const GET_CONSTANTS = `${namespace}/Constants Get`;
export const GET_CONSTANTS_SUCCESS = `${namespace}/Constants Get Success`;
export const GET_CONSTANTS_FAIL = `${namespace}/Constants Get Fail`;

export const GET_CONFIG = `${namespace}/Config Get`;
export const GET_CONFIG_SUCCESS = `${namespace}/Config Get Success`;
export const GET_CONFIG_FAIL = `${namespace}/Config Get Fail`;

export const GET_DOC_TYPES = `${namespace}/Doc Types Get`;
export const GET_DOC_TYPES_SUCCESS = `${namespace}/Doc Types Get Success`;
export const GET_DOC_TYPES_FAIL = `${namespace}/Doc Types Get Fail`;

export const GET_ITALY_CITIES = `${namespace}/Italy Cities Get`;
export const GET_ITALY_CITIES_SUCCESS = `${namespace}/Italy Cities Get Success`;
export const GET_ITALY_CITIES_FAIL = `${namespace}/Italy Cities Get Fail`;

export const GET_COUNTRIES = `${namespace}/Countries Get`;
export const GET_COUNTRIES_SUCCESS = `${namespace}/Countries Get Success`;
export const GET_COUNTRIES_FAIL = `${namespace}/Countries Get Fail`;
