import moment from 'moment';
import CryptoJS from 'crypto-js';
import { env } from "@/config/.env";

export const filterObject = (obj) => {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === 'object') obj[key] = filterObject(obj[key]); //recursive for objects
    else if (obj[key] === null || obj[key] === "") delete obj[key];         //remove empty properties
    // if (typeof obj[key] === 'object' && Object.keys(obj[key]).length == 0) delete obj[key]; //remove empty objects
  });
  return obj;
};

export const objectFlip = (obj) => {
  return Object.keys(obj).reduce((ret, key) => {
    ret[obj[key]] = key;
    return ret;
  }, {});
};

/**
 * @returns string
 */
export const lang = (json, locale) => {
  if (!json || !locale) {
    return null;
  }

  return json[locale];
};

/**
 * @param amount
 * @param currency
 * @returns {*}
 */
export const formatMoney = (amount, currency, format = 'en-US') => {
  if (!amount) {
    amount = 0;
  }

  amount = parseFloat(amount);
  amount = amount.toFixed(2);

  if (!currency) {
    return amount;
  }

  const formatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2
  });

  return formatter.format(amount);
};

/**
 * @param a {array}
 * @param f {string}
 * @returns {*}
 */
export const max = (a, f) => a.reduce((m, x) => parseFloat(m[f]) > parseFloat(x[f]) ? m : x)

/**
 * @param a {array}
 * @param f {string}
 * @returns {*}
 */
export const min = (a, f) => a.reduce((m, x) => parseFloat(m[f]) < parseFloat(x[f]) ? m : x);

/**
 * @param date
 * @param format
 * @returns {boolean}
 */
export const isValidDate = (date, format) => {
  return moment(date, format).isValid();
};

/**
 * @param date
 * @param format
 * @returns {*}
 */
export const toDateFormat = (date, format) => {
  format = format || 'DD/MM/YYYY';

  if (!isValidDate(date, 'YYYY-MM-DD')) {
    return null;
  }

  return moment(date, 'YYYY-MM-DD').format(format);
};

/**
 * @param startDate
 * @param endDate
 */
export const dateDiffInDays = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return 0;
  }

  endDate = moment(endDate);
  startDate = moment(startDate);

  return endDate.diff(startDate, 'd');
};

/**
 * @param count
 * @returns {Array}
 */
export const range = (count) => {
  let range = [];

  for (let i = 0; i < count; i++) {
    range.push(i);
  }

  return range;
};

/**
 * @param start
 * @param end
 * @returns {*[]}
 */
export const arrayFrom = (start, end) => {
  let range = [];

  for (let i = start; i <= end; i++) {
    range.push(i);
  }

  return range;
};

/**
 * @param encryption
 * @param secret
 * @returns {string}
 */
export const encrypt = (encryption) => {
  return CryptoJS.AES.encrypt(encryption, env.APP_KEY).toString();
};

/**
 *
 * @param decryption
 * @param secret
 * @returns {string}
 */
export const decrypt = (decryption) => {
  return CryptoJS.AES.decrypt(decryption, env.APP_KEY).toString(CryptoJS.enc.Utf8);
};

/**
 *
 * @param callback
 */
export const ip = (callback) => {
  fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
      callback(ip);
    });
};

/**
 *
 * @param obj
 * @param form
 * @param namespace
 * @returns {FormData}
 */
export const objectToFormData = (obj, form, namespace) => {
  let fd = form || new FormData();
  let formKey;
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File) &&
        obj[property] !== null
      ) {
        this.objectToFormData(obj[property], fd, property);
      } else {
        if (obj[property] !== false) {
          const value = obj[property] === null ? '' : obj[property];
          fd.append(formKey, value);
        }
      }
    }
  }
  return fd;
};

/**
 * 
 * @param {*} func 
 * @param {*} wait 
 * @returns 
 */
 export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
