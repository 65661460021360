import reducer from "../core/reducer";

import {
  GET_UNIT_CATEGORIES_PAGINATION,
  GET_UNIT_CATEGORIES_PAGINATION_FAIL,
  GET_UNIT_CATEGORIES_PAGINATION_SUCCESS,
  GET_UNIT_CATEGORIES_SINGLE,
  GET_UNIT_CATEGORIES_SINGLE_FAIL,
  GET_UNIT_CATEGORIES_SINGLE_SUCCESS,
} from "./constants";

export default reducer({
  crud: {
    getPaginationRequest: {
      action: GET_UNIT_CATEGORIES_PAGINATION,
      endPoint: "unit-categories/paginated",
      mutationFail: GET_UNIT_CATEGORIES_PAGINATION_FAIL,
      mutationSuccess: GET_UNIT_CATEGORIES_PAGINATION_SUCCESS
    },
  },
  actions: [
    {
      name: 'findRequest',
      action: GET_UNIT_CATEGORIES_SINGLE,
      endPoint: 'unit-categories/find',
      mutations: {
        fail: GET_UNIT_CATEGORIES_SINGLE_FAIL,
        success: GET_UNIT_CATEGORIES_SINGLE_SUCCESS,
      },
    },
  ]
});
