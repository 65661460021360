import Cookies from "universal-cookie";

export default (ctx, inject) => {
  const cookies = new Cookies();
  const session = {
    get(key) {
      return JSON.parse(localStorage.getItem(key));
    },
    set(key, value, options = {}) {
      return localStorage.setItem(key, JSON.stringify(value));
    },
    remove(key, options = {}) {
      return localStorage.removeItem(key);
    },
    getCookie(key) {
      return cookies.get(key);
    },
    setCookie(key, value, options = {}) {
      return cookies.set(key, value, options);
    },
    removeCookie(key, options = {}) {
      return cookies.remove(key, options);
    },
  };

  inject('session', session);
};
