const namespace = 'guests';

export const GET_GUESTS_PAGINATION = `${namespace}/Pagination Get`;
export const GET_GUESTS_PAGINATION_SUCCESS = `${namespace}/Pagination Get Success`;
export const GET_GUESTS_PAGINATION_FAIL = `${namespace}/Pagination Get Fail`;

export const GET_GUESTS_SINGLE = `${namespace}/Single Get`;
export const GET_GUESTS_SINGLE_SUCCESS = `${namespace}/Single Get Success`;
export const GET_GUESTS_SINGLE_FAIL = `${namespace}/Single Get Fail`;

export const CREATE_GUESTS = `${namespace}/Create`;
export const CREATE_GUESTS_SUCCESS = `${namespace}/Create Success`;
export const CREATE_GUESTS_FAIL = `${namespace}/Create Fail`;
export const CREATE_GUESTS_RESET = `${namespace}/Create Reset`;

export const UPDATE_GUESTS = `${namespace}/Update`;
export const UPDATE_GUESTS_SUCCESS = `${namespace}/Update Success`;
export const UPDATE_GUESTS_FAIL = `${namespace}/Update Fail`;
export const UPDATE_GUESTS_RESET = `${namespace}/Update Reset`;

export const REMOVE_GUESTS = `${namespace}/Remove`;
export const REMOVE_GUESTS_SUCCESS = `${namespace}/Remove Success`;
export const REMOVE_GUESTS_FAIL = `${namespace}/Remove Fail`;

export const GET_GUESTS_ALL = `${namespace}/All Get`;
export const GET_GUESTS_ALL_SUCCESS = `${namespace}/All Get Success`;
export const GET_GUESTS_ALL_FAIL = `${namespace}/All Get Fail`;

export const GET_GUESTS_GROUP = `${namespace}/Group Get`;
export const GET_GUESTS_GROUP_SUCCESS = `${namespace}/Group Get Success`;
export const GET_GUESTS_GROUP_FAIL = `${namespace}/Group Get Fail`;

export const GET_GUESTS_FAMILY = `${namespace}/Family Get`;
export const GET_GUESTS_FAMILY_SUCCESS = `${namespace}/Family Get Success`;
export const GET_GUESTS_FAMILY_FAIL = `${namespace}/Family Get Fail`;

export const VALIDATE_DGC = `${namespace}/Validate DGC`;
export const VALIDATE_DGC_SUCCESS = `${namespace}/Validate DGC Success`;
export const VALIDATE_DGC_FAIL = `${namespace}/Validate DGC Fail`;
