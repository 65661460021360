const namespace = 'quotes';

export const SET_QUOTE_FORM = `${namespace}/Form Set`;
export const RESET_QUOTE_FORM = `${namespace}/Form Reset`;

export const GET_QUOTE_BY_TOKEN = `${namespace}/By Token Get`;
export const GET_QUOTE_BY_TOKEN_SUCCESS = `${namespace}/By Token Get Success`;
export const GET_QUOTE_BY_TOKEN_FAIL = `${namespace}/By Token Get Fail`;

export const QUOTE_CREATE_RESERVATIONS = `${namespace}/Reservations Create`;
export const QUOTE_CREATE_RESERVATIONS_SUCCESS = `${namespace}/Reservations Create Success`;
export const QUOTE_CREATE_RESERVATIONS_FAIL = `${namespace}/Reservations Create Fail`;

export const QUOTE_CONFIRM_RESERVATIONS = `${namespace}/Reservations Confirm`;
export const QUOTE_CONFIRM_RESERVATIONS_SUCCESS = `${namespace}/Reservations Confirm Success`;
export const QUOTE_CONFIRM_RESERVATIONS_FAIL = `${namespace}/Reservations Confirm Fail`;

export const QUOTE_ACTIVATE_STRIPE = `${namespace}/Activate Stripe`;
export const QUOTE_ACTIVATE_STRIPE_SUCCESS = `${namespace}/Activate Stripe Success`;
export const QUOTE_ACTIVATE_STRIPE_FAIL = `${namespace}/Activate Stripe Fail`;

export const QUOTE_REPLACE = `${namespace}/Replace`;
export const QUOTE_REPLACE_SUCCESS = `${namespace}/Replace Success`;
export const QUOTE_REPLACE_FAIL = `${namespace}/Replace Fail`;

export const QUOTES_RETRIEVE_SECOM_CARD_UI = `${namespace}/Retrieve Secom Card UI`;
export const QUOTES_RETRIEVE_SECOM_CARD_UI_SUCCESS = `${namespace}/Retrieve Secom Card UI Success`;
export const QUOTES_RETRIEVE_SECOM_CARD_UI_FAIL = `${namespace}/Retrieve Secom Card UI Fail`;

export const QUOTES_STORE_SECOM_CARD = `${namespace}/Store Secom Card`;
export const QUOTES_STORE_SECOM_CARD_SUCCESS = `${namespace}/Store Secom Card Success`;
export const QUOTES_STORE_SECOM_CARD_FAIL = `${namespace}/Store Secom Card Fail`;

export const SUBMIT_QUOTE_REQUEST = `${namespace}/Submit Quote Request`;
export const SUBMIT_QUOTE_REQUEST_SUCCESS = `${namespace}/Submit Quote Request Success`;
export const SUBMIT_QUOTE_REQUEST_FAIL = `${namespace}/Submit Quote Request Fail`;
