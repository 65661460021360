import reducer from "./core/reducer";

import {
  GET_CONFIG,
  GET_CONSTANTS,
} from './data/constants';

import {
  GET_BOOKING_NETWORKS,
} from './booking-networks/constants';

/**
 * @param hostname
 * @returns {boolean}
 */
const isPortal = (hostname) => {
  let url = new URL(`http://${hostname}`);
  let portal = new URL(process.env.CUSTOMER_PORTAL_URL);

  return url.hostname === portal.hostname;
};

export default reducer({
  custom: {
    actions: {
      async nuxtServerInit(ctx, {req, store}) {
        if (isPortal(req.headers.host)) {
          await store.dispatch(GET_CONSTANTS);
          return;
        }

        await store.dispatch(GET_CONFIG);
        await store.dispatch(GET_CONSTANTS);
        await store.dispatch(GET_BOOKING_NETWORKS);
      },
    }
  }
});
