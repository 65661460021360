import reducer from "../core/reducer";

import {
  GET_TRANSACTION,
  GET_TRANSACTION_FAIL,
  GET_TRANSACTION_SUCCESS,
} from "./constants";

export default reducer({
  actions: [
    {
      name: 'getRequest',
      action: GET_TRANSACTION,
      endPoint: '/transactions/{transaction_id}',
      mutations: {
        fail: GET_TRANSACTION_FAIL,
        success: GET_TRANSACTION_SUCCESS,
      },
    },
  ]
});
