import reducer from '../core/reducer';
import { baseName } from '../core/functions';

import {
  SET_BROWSER
} from './constants';

export default reducer({
  custom: {
    state: {
      isBrowser: false
    },
    mutations: {
      [baseName(SET_BROWSER)](state, payload) {
        state.isBrowser = payload;
      }
    }
  }
});
