import reducer from "../core/reducer";
import {baseName} from "../core/functions";
import {cloneDeep} from "lodash";

import {
  SEARCH_SET,
  SEARCH_RESET,
  SEARCH_SET_PROPERTY,
  SEARCH_RESET_PROPERTY,
  SEARCH_DATA,
  SEARCH_DATA_SUCCESS,
  SEARCH_DATA_FAIL,
  SEARCH_SINGLE,
  SEARCH_SINGLE_SUCCESS,
  SEARCH_SINGLE_FAIL,
  SEARCH_SINGLE_BY_SLUG,
  SEARCH_SINGLE_BY_SLUG_SUCCESS,
  SEARCH_SINGLE_BY_SLUG_FAIL,
  SEARCH_COMBINATIONS,
  SEARCH_COMBINATIONS_SUCCESS,
  SEARCH_COMBINATIONS_FAIL,
} from "./constants";

const filters = {
  latitude: null,
  longitude: null,
  radius: null,
  adults: 2,
  children: 0,
  children_ages: [],
  amenities: [],
  bedrooms: null,
  bathrooms: null,
  beds: null,
  size: [],
  room_types: [],
  min_price: null,
  max_price: null,
  start_date: null,
  end_date: null
};

const setPropertyAction = baseName(SEARCH_SET_PROPERTY);
const resetPropertyAction = baseName(SEARCH_RESET_PROPERTY);

export default reducer({
  custom: {
    state: {
      filters: cloneDeep(filters),
      property: {
        id: null,
        slug: null,
      }
    },
    actions: {
      [setPropertyAction](context, payload) {
        context.commit(`${setPropertyAction}_MUTATE`, payload);
      },
      [resetPropertyAction](context) {
        context.commit(`${resetPropertyAction}_MUTATE`);
      },
    },
    mutations: {
      [`${setPropertyAction}_MUTATE`](state, { id, slug }) {
        state.property.id = id;
        state.property.slug = slug;
      },
      [`${resetPropertyAction}_MUTATE`](state) {
        state.property.id = null;
        state.property.slug = null;
      },
      [baseName(SEARCH_RESET)](state) {
        state.filters = cloneDeep(filters)
      },
      [baseName(SEARCH_SET)](state, payload) {
        if (payload.hasOwnProperty('adults')) {
          payload.adults = parseInt(payload.adults);
        }
        if (payload.hasOwnProperty('children')) {
          payload.children = parseInt(payload.children);
        }

        state.filters = {
          ...state.filters,
          ...payload
        };
      },
    }
  },
  actions: [
    {
      name: 'dataRequest',
      action: SEARCH_DATA,
      endPoint: 'search/data',
      mutations: {
        fail: SEARCH_DATA_FAIL,
        success: SEARCH_DATA_SUCCESS,
      },
    },
    {
      name: 'singleRequest',
      action: SEARCH_SINGLE,
      endPoint: 'search/single/{id}',
      mutations: {
        fail: SEARCH_SINGLE_FAIL,
        success: SEARCH_SINGLE_SUCCESS,
      },
    },
    {
      name: 'singleRequest',
      action: SEARCH_SINGLE_BY_SLUG,
      endPoint: 'search/single-by-slug/{slug}',
      mutations: {
        fail: SEARCH_SINGLE_BY_SLUG_FAIL,
        success: SEARCH_SINGLE_BY_SLUG_SUCCESS,
      },
    },
    {
      name: 'combinationsRequest',
      action: SEARCH_COMBINATIONS,
      endPoint: 'search/combinations/{id}',
      mutations: {
        fail: SEARCH_COMBINATIONS_FAIL,
        success: SEARCH_COMBINATIONS_SUCCESS,
      },
    },
  ]
});
