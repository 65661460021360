import crud from './crud';
import action from './action';

export default (options) => {
  let state = {};
  let getters = {};
  let actions = {};
  let mutations = {};

  if (options.crud) {
    const crudResponse = crud(options.crud);

    state = { ...state, ...crudResponse.state };
    getters = { ...getters, ...crudResponse.getters };
    actions = { ...actions, ...crudResponse.actions };
    mutations = { ...mutations, ...crudResponse.mutations };
  }
  if (options.actions) {
    options.actions.map((actionOptions) => {
      const actionResponse = action(actionOptions);

      state = { ...state, ...actionResponse.state };
      getters = { ...getters, ...actionResponse.getters };
      actions = { ...actions, ...actionResponse.actions };
      mutations = { ...mutations, ...actionResponse.mutations };
    });
  }
  if (options.custom && options.custom.state) {
    state = { ...state, ...options.custom.state };
  }
  if (options.custom && options.custom.getters) {
    getters = { ...getters, ...options.custom.getters };
  }
  if (options.custom && options.custom.actions) {
    actions = { ...actions, ...options.custom.actions };
  }
  if (options.custom && options.custom.mutations) {
    mutations = { ...mutations, ...options.custom.mutations };
  }

  return {
    state: () => ({ ...state }),
    getters,
    actions,
    mutations
  };
};
