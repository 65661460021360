import reducer from "../core/reducer";

import {
  GET_CLIENTS_BY_FIELD,
  GET_CLIENTS_BY_FIELD_SUCCESS,
  GET_CLIENTS_BY_FIELD_FAIL,
  GET_CLIENTS_BY_TOKEN,
  GET_CLIENTS_BY_TOKEN_FAIL,
  GET_CLIENTS_BY_TOKEN_SUCCESS,
  REPLACE_CLIENTS,
  REPLACE_CLIENTS_SUCCESS,
  REPLACE_CLIENTS_FAIL,
  GET_CLIENTS_STRIPE_PAYMENT_METHOD,
  GET_CLIENTS_STRIPE_PAYMENT_METHOD_SUCCESS,
  GET_CLIENTS_STRIPE_PAYMENT_METHOD_FAIL,
  CONFIRM_CLIENTS_STRIPE_SETUP_INTENT,
  CONFIRM_CLIENTS_STRIPE_SETUP_INTENT_SUCCESS,
  CONFIRM_CLIENTS_STRIPE_SETUP_INTENT_FAIL,
  CANCEL_CLIENTS_STRIPE_SETUP_INTENT,
  CANCEL_CLIENTS_STRIPE_SETUP_INTENT_SUCCESS,
  CANCEL_CLIENTS_STRIPE_SETUP_INTENT_FAIL,
  CREATE_CLIENTS_STRIPE_SETUP_INTENT,
  CREATE_CLIENTS_STRIPE_SETUP_INTENT_SUCCESS,
  CREATE_CLIENTS_STRIPE_SETUP_INTENT_FAIL,
  DETACH_CLIENTS_STRIPE_PAYMENT_METHOD,
  DETACH_CLIENTS_STRIPE_PAYMENT_METHOD_SUCCESS,
  DETACH_CLIENTS_STRIPE_PAYMENT_METHOD_FAIL,
  SEARCH_CLIENT,
  SEARCH_CLIENT_SUCCESS,
  SEARCH_CLIENT_FAIL,
} from "./constants";

export default reducer({
  actions: [
    {
      name: 'getByTokenRequest',
      action: GET_CLIENTS_BY_TOKEN,
      endPoint: 'clients/by-token/{token}',
      mutations: {
        fail: GET_CLIENTS_BY_TOKEN_FAIL,
        success: GET_CLIENTS_BY_TOKEN_SUCCESS,
      },
    },
    {
      name: 'getByFieldRequest',
      action: GET_CLIENTS_BY_FIELD,
      endPoint: 'clients/by-field',
      mutations: {
        fail: GET_CLIENTS_BY_FIELD_FAIL,
        success: GET_CLIENTS_BY_FIELD_SUCCESS,
      },
    },
    {
      method: 'patch',
      name: 'replaceClientRequest',
      action: REPLACE_CLIENTS,
      endPoint: 'clients/replace/{id}',
      mutations: {
        fail: REPLACE_CLIENTS_FAIL,
        success: REPLACE_CLIENTS_SUCCESS,
      },
    },
    {
      name: 'getStripePaymentMethodRequest',
      action: GET_CLIENTS_STRIPE_PAYMENT_METHOD,
      endPoint: 'clients/{id}/stripe/payment-method',
      mutations: {
        fail: GET_CLIENTS_STRIPE_PAYMENT_METHOD_FAIL,
        success: GET_CLIENTS_STRIPE_PAYMENT_METHOD_SUCCESS,
      },
    },
    {
      method: 'delete',
      name: 'detachStripePaymentMethodRequest',
      action: DETACH_CLIENTS_STRIPE_PAYMENT_METHOD,
      endPoint: 'clients/{id}/stripe/payment-method/detach',
      mutations: {
        fail: DETACH_CLIENTS_STRIPE_PAYMENT_METHOD_FAIL,
        success: DETACH_CLIENTS_STRIPE_PAYMENT_METHOD_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'createStripeSetupIntentRequest',
      action: CREATE_CLIENTS_STRIPE_SETUP_INTENT,
      endPoint: 'clients/{id}/stripe/setup-intent/create',
      mutations: {
        fail: CREATE_CLIENTS_STRIPE_SETUP_INTENT_FAIL,
        success: CREATE_CLIENTS_STRIPE_SETUP_INTENT_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'confirmStripeSetupIntentRequest',
      action: CONFIRM_CLIENTS_STRIPE_SETUP_INTENT,
      endPoint: 'clients/{id}/stripe/setup-intent/confirm',
      mutations: {
        fail: CONFIRM_CLIENTS_STRIPE_SETUP_INTENT_FAIL,
        success: CONFIRM_CLIENTS_STRIPE_SETUP_INTENT_SUCCESS,
      },
    },
    {
      method: 'delete',
      name: 'cancelStripeSetupIntentRequest',
      action: CANCEL_CLIENTS_STRIPE_SETUP_INTENT,
      endPoint: 'clients/{id}/stripe/setup-intent/cancel',
      mutations: {
        fail: CANCEL_CLIENTS_STRIPE_SETUP_INTENT_FAIL,
        success: CANCEL_CLIENTS_STRIPE_SETUP_INTENT_SUCCESS,
      },
    },
    {
      name: 'searchRequest',
      action: SEARCH_CLIENT,
      endPoint: 'clients/search',
      mutations: {
        fail: SEARCH_CLIENT_FAIL,
        success: SEARCH_CLIENT_SUCCESS,
      },
    },
  ]
});
