import reducer from "../core/reducer";

import {
  GET_LEGAL_COMPLIANCE_REQUEST_BY_TOKEN,
  GET_LEGAL_COMPLIANCE_REQUEST_BY_TOKEN_FAIL,
  GET_LEGAL_COMPLIANCE_REQUEST_BY_TOKEN_SUCCESS,
} from "./constants";

export default reducer({
  actions: [
    {
      name: 'getByTokenRequest',
      action: GET_LEGAL_COMPLIANCE_REQUEST_BY_TOKEN,
      endPoint: 'legal-compliance-requests/by-token/{token}',
      mutations: {
        fail: GET_LEGAL_COMPLIANCE_REQUEST_BY_TOKEN_FAIL,
        success: GET_LEGAL_COMPLIANCE_REQUEST_BY_TOKEN_SUCCESS,
      },
    },
  ]
});
