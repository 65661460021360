const namespace = 'clients';

export const GET_CLIENTS_BY_FIELD = `${namespace}/By Field Get`;
export const GET_CLIENTS_BY_FIELD_SUCCESS = `${namespace}/By Field Get Success`;
export const GET_CLIENTS_BY_FIELD_FAIL = `${namespace}/By Field Get Fail`;

export const GET_CLIENTS_BY_TOKEN = `${namespace}/By Token Get`;
export const GET_CLIENTS_BY_TOKEN_SUCCESS = `${namespace}/By Token Get Success`;
export const GET_CLIENTS_BY_TOKEN_FAIL = `${namespace}/By Token Get Fail`;

export const REPLACE_CLIENTS = `${namespace}/Client Replace`;
export const REPLACE_CLIENTS_SUCCESS = `${namespace}/Client Replace Success`;
export const REPLACE_CLIENTS_FAIL = `${namespace}/Client Replace Fail`;

export const GET_CLIENTS_STRIPE_PAYMENT_METHOD = `${namespace}/Stripe Payment Method Get`;
export const GET_CLIENTS_STRIPE_PAYMENT_METHOD_SUCCESS = `${namespace}/Stripe Payment Method Get Success`;
export const GET_CLIENTS_STRIPE_PAYMENT_METHOD_FAIL = `${namespace}/Stripe Payment Method Get Fail`;

export const DETACH_CLIENTS_STRIPE_PAYMENT_METHOD = `${namespace}/Stripe Payment Method Cancel`;
export const DETACH_CLIENTS_STRIPE_PAYMENT_METHOD_SUCCESS = `${namespace}/Stripe Payment Method Cancel Success`;
export const DETACH_CLIENTS_STRIPE_PAYMENT_METHOD_FAIL = `${namespace}/Stripe Payment Method Cancel Fail`;

export const CREATE_CLIENTS_STRIPE_SETUP_INTENT = `${namespace}/Stripe Setup Intent Create`;
export const CREATE_CLIENTS_STRIPE_SETUP_INTENT_SUCCESS = `${namespace}/Stripe Setup Intent Create Success`;
export const CREATE_CLIENTS_STRIPE_SETUP_INTENT_FAIL = `${namespace}/Stripe Setup Intent Create Fail`;

export const CONFIRM_CLIENTS_STRIPE_SETUP_INTENT = `${namespace}/Stripe Setup Intent Confirm`;
export const CONFIRM_CLIENTS_STRIPE_SETUP_INTENT_SUCCESS = `${namespace}/Stripe Setup Intent Confirm Success`;
export const CONFIRM_CLIENTS_STRIPE_SETUP_INTENT_FAIL = `${namespace}/Stripe Setup Intent Confirm Fail`;

export const CANCEL_CLIENTS_STRIPE_SETUP_INTENT = `${namespace}/Stripe Setup Intent Cancel`;
export const CANCEL_CLIENTS_STRIPE_SETUP_INTENT_SUCCESS = `${namespace}/Stripe Setup Intent Cancel Success`;
export const CANCEL_CLIENTS_STRIPE_SETUP_INTENT_FAIL = `${namespace}/Stripe Setup Intent Cancel Fail`;

export const SEARCH_CLIENT = `${namespace}/Search`;
export const SEARCH_CLIENT_SUCCESS = `${namespace}/Search Success`;
export const SEARCH_CLIENT_FAIL = `${namespace}/Search Fail`;
