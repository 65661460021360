import {
  lang,
  formatMoney,
  toDateFormat,
  dateDiffInDays,
  range,
  encrypt,
  decrypt,
  debounce,
} from "../utils/helpers";

export default (context, inject) => {
  inject('lang', lang);
  inject('formatMoney', formatMoney);
  inject('toDateFormat', toDateFormat);
  inject('dateDiffInDays', dateDiffInDays);
  inject('range', range);
  inject('encrypt', encrypt);
  inject('decrypt', decrypt);
  inject('debounce', debounce);
}
