import reducer from "../core/reducer";
import { baseName } from '../core/functions';
import { cloneDeep } from "lodash";

import {
  SET_FILTERS,
  RESET_FILTERS,
} from './constants';

const defaultFilters = {
  latitude: null,
  longitude: null,
  radius: null,
  adults: 2,
  children: 0,
  amenities: [],
  bedrooms: null,
  bathrooms: null,
  beds: null,
  size: [],
  room_types: [],
  min_price: null,
  max_price: null,
  start_date: null,
  end_date: null
};

export default reducer({
  custom: {
    state: {
      filters: cloneDeep(defaultFilters),
    },
    mutations: {
      [baseName(RESET_FILTERS)](state) {
        state = cloneDeep(defaultFilters)
      },
      [baseName(SET_FILTERS)](state, payload) {
        state.filters = {
          ...state.filters,
          ...payload
        };
      },
    }
  }
});
