import reducer from "../core/reducer";

import {
  GET_PROPERTIES_PAGINATION,
  GET_PROPERTIES_PAGINATION_FAIL,
  GET_PROPERTIES_PAGINATION_SUCCESS,
  GET_PROPERTIES_SINGLE,
  GET_PROPERTIES_SINGLE_FAIL,
  GET_PROPERTIES_SINGLE_SUCCESS,
  GET_PROPERTIES_AVG_PRICE,
  GET_PROPERTIES_AVG_PRICE_FAIL,
  GET_PROPERTIES_AVG_PRICE_SUCCESS,
  GET_PROPERTIES_SINGLE_COMBINATIONS,
  GET_PROPERTIES_SINGLE_COMBINATIONS_FAIL,
  GET_PROPERTIES_SINGLE_COMBINATIONS_SUCCESS,
} from "./constants";

export default reducer({
  crud: {
    getPaginationRequest: {
      action: GET_PROPERTIES_PAGINATION,
      endPoint: "properties/paginated",
      mutationFail: GET_PROPERTIES_PAGINATION_FAIL,
      mutationSuccess: GET_PROPERTIES_PAGINATION_SUCCESS
    },
    getSingleRequest: {
      action: GET_PROPERTIES_SINGLE,
      endPoint: "properties/single",
      mutationFail: GET_PROPERTIES_SINGLE_FAIL,
      mutationSuccess: GET_PROPERTIES_SINGLE_SUCCESS
    },
  },
  actions: [
    {
      name: 'getAvgPriceRequest',
      action: GET_PROPERTIES_AVG_PRICE,
      endPoint: 'properties/avg-price',
      mutations: {
        fail: GET_PROPERTIES_AVG_PRICE_FAIL,
        success: GET_PROPERTIES_AVG_PRICE_SUCCESS,
      },
    },
    {
      name: 'getSingleCombinationsRequest',
      action: GET_PROPERTIES_SINGLE_COMBINATIONS,
      endPoint: 'properties/{id}/combinations',
      mutations: {
        fail: GET_PROPERTIES_SINGLE_COMBINATIONS_FAIL,
        success: GET_PROPERTIES_SINGLE_COMBINATIONS_SUCCESS,
      },
    },
  ]
});
