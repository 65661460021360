import reducer from '../core/reducer';

import {
  GET_RESERVATIONS_PAGINATION,
  GET_RESERVATIONS_PAGINATION_FAIL,
  GET_RESERVATIONS_PAGINATION_SUCCESS,
  ACTIVATE_PAYMENT,
  ACTIVATE_PAYMENT_FAIL,
  ACTIVATE_PAYMENT_SUCCESS,
  CONFIRM_PAYMENT,
  CONFIRM_PAYMENT_FAIL,
  CONFIRM_PAYMENT_SUCCESS,
  RESERVATIONS_REPLACE,
  RESERVATIONS_REPLACE_SUCCESS,
  RESERVATIONS_REPLACE_FAIL,
  RESERVATIONS_REPLACE_RESET,
  RESERVATIONS_CANCEL,
  RESERVATIONS_CANCEL_SUCCESS,
  RESERVATIONS_CANCEL_FAIL,
  RESERVATIONS_CANCEL_RESET,
  LOCK_RESERVATION_SMART_LOCK,
  LOCK_RESERVATION_SMART_LOCK_SUCCESS,
  LOCK_RESERVATION_SMART_LOCK_FAIL,
  LOCK_RESERVATION_SMART_LOCK_RESET,
  UNLOCK_RESERVATION_SMART_LOCK,
  UNLOCK_RESERVATION_SMART_LOCK_SUCCESS,
  UNLOCK_RESERVATION_SMART_LOCK_FAIL,
  UNLOCK_RESERVATION_SMART_LOCK_RESET,
} from './constants';

export default reducer({
  crud: {
    getPaginationRequest: {
      action: GET_RESERVATIONS_PAGINATION,
      endPoint: "reservations",
      mutationFail: GET_RESERVATIONS_PAGINATION_FAIL,
      mutationSuccess: GET_RESERVATIONS_PAGINATION_SUCCESS
    },
    replaceRequest: {
      action: RESERVATIONS_REPLACE,
      endPoint: "reservations/replace",
      mutationFail: RESERVATIONS_REPLACE_FAIL,
      mutationSuccess: RESERVATIONS_REPLACE_SUCCESS,
      actionReset: RESERVATIONS_REPLACE_RESET,
    }
  },
  actions: [
    {
      method: 'post',
      name: 'activatePaymentRequest',
      action: ACTIVATE_PAYMENT,
      endPoint: 'reservations/activate-payment',
      mutations: {
        fail: ACTIVATE_PAYMENT_FAIL,
        success: ACTIVATE_PAYMENT_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'confirmPaymentRequest',
      action: CONFIRM_PAYMENT,
      endPoint: 'reservations/confirm-payment',
      mutations: {
        fail: CONFIRM_PAYMENT_FAIL,
        success: CONFIRM_PAYMENT_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'cancelRequest',
      action: RESERVATIONS_CANCEL,
      actionReset: RESERVATIONS_CANCEL_RESET,
      endPoint: 'reservations/cancel/{id}',
      mutations: {
        fail: RESERVATIONS_CANCEL_FAIL,
        success: RESERVATIONS_CANCEL_SUCCESS,
      }
    },
    {
      method: 'post',
      name: 'lockSmartLockRequest',
      action: LOCK_RESERVATION_SMART_LOCK,
      actionReset: LOCK_RESERVATION_SMART_LOCK_RESET,
      endPoint: 'reservations/lock-smart-lock/{reservation_id}/{id}',
      mutations: {
        fail: LOCK_RESERVATION_SMART_LOCK_FAIL,
        success: LOCK_RESERVATION_SMART_LOCK_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'unlockSmartLockRequest',
      action: UNLOCK_RESERVATION_SMART_LOCK,
      actionReset: UNLOCK_RESERVATION_SMART_LOCK_RESET,
      endPoint: 'reservations/unlock-smart-lock/{reservation_id}/{id}',
      mutations: {
        fail: UNLOCK_RESERVATION_SMART_LOCK_FAIL,
        success: UNLOCK_RESERVATION_SMART_LOCK_SUCCESS,
      },
    },
  ]
});
