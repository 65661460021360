import reducer from '../core/reducer';

import {
  GET_CONSTANTS,
  GET_CONSTANTS_FAIL,
  GET_CONSTANTS_SUCCESS,
  GET_CONFIG,
  GET_CONFIG_FAIL,
  GET_CONFIG_SUCCESS,
  GET_DOC_TYPES,
  GET_DOC_TYPES_FAIL,
  GET_DOC_TYPES_SUCCESS,
  GET_ITALY_CITIES,
  GET_ITALY_CITIES_FAIL,
  GET_ITALY_CITIES_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_SUCCESS,
} from './constants';

export default reducer({
  actions: [
    {
      name: 'getConstantsRequest',
      action: GET_CONSTANTS,
      endPoint: 'c/constants',
      mutations: {
        fail: GET_CONSTANTS_FAIL,
        success: GET_CONSTANTS_SUCCESS,
      },
    },
    {
      name: 'getConfigRequest',
      action: GET_CONFIG,
      endPoint: 'c/all',
      mutations: {
        fail: GET_CONFIG_FAIL,
        success: GET_CONFIG_SUCCESS,
      },
    },
    {
      name: 'getDocTypesRequest',
      action: GET_DOC_TYPES,
      endPoint: 'c/doc-types',
      mutations: {
        fail: GET_DOC_TYPES_FAIL,
        success: GET_DOC_TYPES_SUCCESS,
      },
    },
    {
      name: 'getItalyCitiesRequest',
      action: GET_ITALY_CITIES,
      endPoint: 'c/italy-cities',
      mutations: {
        fail: GET_ITALY_CITIES_FAIL,
        success: GET_ITALY_CITIES_SUCCESS,
      },
    },
    {
      name: 'getCountriesRequest',
      action: GET_COUNTRIES,
      endPoint: 'c/iso-countries-2-letter',
      mutations: {
        fail: GET_COUNTRIES_FAIL,
        success: GET_COUNTRIES_SUCCESS,
      },
    },
  ]
});
