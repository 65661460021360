import reducer from "../core/reducer";
import { baseName } from "../core/functions";

import {
  VALIDATE_EXTRAS,
  VALIDATE_EXTRAS_SUCCESS,
  VALIDATE_EXTRAS_FAIL,
  VALIDATE_DETAILS,
  VALIDATE_DETAILS_SUCCESS,
  VALIDATE_DETAILS_FAIL,
  CREATE_RESERVATIONS,
  CREATE_RESERVATIONS_SUCCESS,
  CREATE_RESERVATIONS_FAIL,
  CONFIRM_RESERVATIONS,
  CONFIRM_RESERVATIONS_SUCCESS,
  CONFIRM_RESERVATIONS_FAIL,
  SET_CHECKOUT_FORM,
  RESET_CHECKOUT_FORM,
  ACTIVATE_STRIPE,
  ACTIVATE_STRIPE_FAIL,
  ACTIVATE_STRIPE_SUCCESS,
  RETRIEVE_SECOM_CARD_UI,
  RETRIEVE_SECOM_CARD_UI_SUCCESS,
  RETRIEVE_SECOM_CARD_UI_FAIL,
  STORE_SECOM_CARD,
  STORE_SECOM_CARD_SUCCESS,
  STORE_SECOM_CARD_FAIL,
  STORE_SECOM_CARD_WITH_PAYMENT_METHOD,
  STORE_SECOM_CARD_WITH_PAYMENT_METHOD_SUCCESS,
  STORE_SECOM_CARD_WITH_PAYMENT_METHOD_FAIL,
} from "./constants";

const setAction = baseName(SET_CHECKOUT_FORM);
const resetAction = baseName(RESET_CHECKOUT_FORM);

export default reducer({
  custom: {
    state: {
      form: {},
    },
    actions: {
      [setAction](context, payload) {
        context.commit(`${setAction}_MUTATE`, payload);
      },
      [resetAction](context) {
        context.commit(`${resetAction}_MUTATE`);
      },
    },
    mutations: {
      [`${setAction}_MUTATE`](state, payload) {
        state.form = {
          ...state.form,
          ...payload
        }
      },
      [`${resetAction}_MUTATE`](state) {
        state.form = {}
      },
    }
  },
  actions: [
    {
      method: 'post',
      name: 'validateExtrasRequest',
      action: VALIDATE_EXTRAS,
      endPoint: 'checkout/validate/extras',
      mutations: {
        fail: VALIDATE_EXTRAS_FAIL,
        success: VALIDATE_EXTRAS_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'validateDetailsRequest',
      action: VALIDATE_DETAILS,
      endPoint: 'checkout/validate/details',
      mutations: {
        fail: VALIDATE_DETAILS_FAIL,
        success: VALIDATE_DETAILS_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'createReservationsRequest',
      action: CREATE_RESERVATIONS,
      endPoint: 'checkout/reservations/create',
      mutations: {
        fail: CREATE_RESERVATIONS_FAIL,
        success: CREATE_RESERVATIONS_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'confirmReservationsRequest',
      action: CONFIRM_RESERVATIONS,
      endPoint: 'checkout/reservations/confirm',
      mutations: {
        fail: CONFIRM_RESERVATIONS_FAIL,
        success: CONFIRM_RESERVATIONS_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'activateStripeRequest',
      action: ACTIVATE_STRIPE,
      endPoint: 'checkout/stripe/activate',
      mutations: {
        fail: ACTIVATE_STRIPE_FAIL,
        success: ACTIVATE_STRIPE_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'getSecomCardUIRequest',
      action: RETRIEVE_SECOM_CARD_UI,
      endPoint: 'checkout/secom/card-ui',
      mutations: {
        fail: RETRIEVE_SECOM_CARD_UI_FAIL,
        success: RETRIEVE_SECOM_CARD_UI_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'storeSecomCardRequest',
      action: STORE_SECOM_CARD,
      endPoint: 'checkout/secom/card-store',
      mutations: {
        fail: STORE_SECOM_CARD_FAIL,
        success: STORE_SECOM_CARD_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'storeSecomCardWithPaymentMethodRequest',
      action: STORE_SECOM_CARD_WITH_PAYMENT_METHOD,
      endPoint: 'checkout/secom/card-store-with-payment-method',
      mutations: {
        fail: STORE_SECOM_CARD_WITH_PAYMENT_METHOD_FAIL,
        success: STORE_SECOM_CARD_WITH_PAYMENT_METHOD_SUCCESS,
      },
    },
  ]
});
