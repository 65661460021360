import { baseName } from './functions';

export default (options) => {
  let state = {};
  let getters = {};
  let actions = {};
  let mutations = {};

  if (options.getPaginationRequest) {
    state = { ...state,
      getPaginationRequest: {
        loading: false,
        success: false,
        fail: false,
        collection: [],
        pagination: {
          page: 1
        },
        errors: {},
        params: {}
      },
    };
    actions = { ...actions,
      [baseName(options.getPaginationRequest.action)](context, params) {
        context.commit('SET_PAGINATION_REQUEST_LOADING', true);
        context.commit('SET_PAGINATION_REQUEST_PARAMS', params);

        return new Promise((resolve, reject) => {
          this.$api.query(options.getPaginationRequest.endPoint, params)
            .then((response) => {
              context.commit(baseName(options.getPaginationRequest.mutationSuccess), response.data);
              resolve(response.data);
            })
            .catch(({ response }) => {
              context.commit(baseName(options.getPaginationRequest.mutationFail), response.data);
              reject(response.data);
            });
        });
      },
    };
    mutations = { ...mutations,
      [baseName(options.getPaginationRequest.mutationSuccess)](state, data) {
        state.getPaginationRequest = {
          loading: false,
          success: true,
          fail: false,
          collection: data.collection,
          pagination: data.pagination,
          errors: {},
          params: {}
        };
      },
      [baseName(options.getPaginationRequest.mutationFail)](state, data) {
        state.getPaginationRequest = {
          loading: false,
          success: false,
          fail: true,
          collection: [],
          pagination: {
            page: 1
          },
          errors: data.errors,
          params: {}
        };
      },
      SET_PAGINATION_REQUEST_PARAMS(state, params) {
        state.getPaginationRequest.params = params;
      },
      SET_PAGINATION_REQUEST_LOADING(state, loading) {
        state.getPaginationRequest.loading = loading
      },
    };
  }
  if (options.getCollectionRequest) {
    state = { ...state,
      getCollectionRequest: {
        loading: false,
        success: false,
        fail: false,
        collection: [],
        errors: {},
        params: {},
      },
    };
    actions = { ...actions,
      [baseName(options.getCollectionRequest.action)](context, params) {
        context.commit('SET_COLLECTION_REQUEST_LOADING', true);
        context.commit('SET_COLLECTION_REQUEST_PARAMS', params);

        return new Promise((resolve, reject) => {
          this.$api.query(options.getCollectionRequest.endPoint, params)
            .then((response) => {
              context.commit(baseName(options.getCollectionRequest.mutationSuccess), response.data);
              resolve(response.data);
            })
            .catch(({ response }) => {
              context.commit(baseName(options.getCollectionRequest.mutationFail), response.data);
              reject(response.data);
            });
        });
      },
    };
    mutations = { ...mutations,
      [baseName(options.getCollectionRequest.mutationSuccess)](state, data) {
        state.getCollectionRequest = {
          loading: false,
          success: true,
          fail: false,
          collection: data.collection,
          errors: {},
          params: {},
        };
      },
      [baseName(options.getCollectionRequest.mutationFail)](state, data) {
        state.getCollectionRequest = {
          loading: false,
          success: false,
          fail: true,
          collection: [],
          errors: data.errors,
          params: {},
        };
      },
      SET_COLLECTION_REQUEST_PARAMS(state, params) {
        state.getCollectionRequest.params = params;
      },
      SET_COLLECTION_REQUEST_LOADING(state, loading) {
        state.getCollectionRequest.loading = loading
      },
    };
  }
  if (options.getSingleRequest) {
    state = { ...state,
      getSingleRequest: {
        loading: false,
        success: false,
        fail: false,
        item: {},
        errors: {},
        params: {},
      },
    };
    actions = { ...actions,
      [baseName(options.getSingleRequest.action)](context, { id, params }) {
        context.commit('SET_SINGLE_REQUEST_LOADING', true);
        context.commit('SET_SINGLE_REQUEST_PARAMS', { id, ...(params || {}) });

        return new Promise((resolve, reject) => {
          this.$api.query(`${options.getSingleRequest.endPoint}/${id}`, params)
            .then((response) => {
              context.commit(baseName(options.getSingleRequest.mutationSuccess), response.data);
              resolve(response.data);
            })
            .catch(({ response }) => {
              context.commit(baseName(options.getSingleRequest.mutationFail), response.data);
              reject(response.data);
            });
        });
      },
    };
    mutations = { ...mutations,
      [baseName(options.getSingleRequest.mutationSuccess)](state, data) {
        state.getSingleRequest = {
          loading: false,
          success: true,
          fail: false,
          item: data,
          errors: {},
          params: {},
        };
      },
      [baseName(options.getSingleRequest.mutationFail)](state, data) {
        state.getSingleRequest = {
          loading: false,
          success: false,
          fail: true,
          item: {},
          errors: data.errors,
          params: {},
        };
      },
      SET_SINGLE_REQUEST_PARAMS(state, params) {
        state.getSingleRequest.params = params;
      },
      SET_SINGLE_REQUEST_LOADING(state, loading) {
        state.getSingleRequest.loading = loading
      },
    };
  }
  if (options.creationRequest) {
    state = { ...state,
      creationRequest: {
        loading: false,
        success: false,
        fail: false,
        validation: {},
        response: {},
        params: {},
      },
    };
    actions = { ...actions,
      [baseName(options.creationRequest.actionReset)](context) {
        context.commit('CREATION_REQUEST_RESET');
      },
      [baseName(options.creationRequest.action)](context, params) {
        context.commit('SET_CREATION_REQUEST_LOADING', true);
        context.commit('SET_CREATION_REQUEST_PARAMS', params);

        return new Promise((resolve, reject) => {
          let method = 'post';

          if (options.creationRequest.isUpload) {
            method = 'postUpload';
          }

          this.$api[method](options.creationRequest.endPoint, params)
            .then(response => {
              context.commit(baseName(options.creationRequest.mutationSuccess), response.data);
              resolve(response.data);
            })
            .catch(({ response }) => {
              context.commit(baseName(options.creationRequest.mutationFail), response.data);
              reject(response.data);
            });
        });
      },
    };
    mutations = { ...mutations,
      [baseName(options.creationRequest.mutationSuccess)](state, data) {
        state.creationRequest = {
          loading: false,
          success: true,
          fail: false,
          validation: {},
          response: data,
          params: {},
        };
      },
      [baseName(options.creationRequest.mutationFail)](state, data) {
        state.creationRequest = {
          loading: false,
          success: false,
          fail: true,
          validation: data.errors,
          response: {},
          params: {},
        };
      },
      SET_CREATION_REQUEST_PARAMS(state, params) {
        state.creationRequest.params = params;
      },
      SET_CREATION_REQUEST_LOADING(state, loading) {
        state.creationRequest.loading = loading
      },
      CREATION_REQUEST_RESET(state) {
        state.creationRequest = {
          loading: false,
          success: false,
          fail: false,
          validation: {},
          response: {},
          params: {},
        };
      },
    };
  }
  if (options.editingRequest) {
    state = { ...state,
      editingRequest: {
        loading: false,
        success: false,
        fail: false,
        validation: {},
        response: {},
        params: {},
      },
    };
    actions = { ...actions,
      [baseName(options.editingRequest.actionReset)](context) {
        context.commit('EDITING_REQUEST_RESET');
      },
      [baseName(options.editingRequest.action)](context, { id, params }) {
        context.commit('SET_EDITING_REQUEST_LOADING', true);
        context.commit('SET_EDITING_REQUEST_PARAMS', { id, ...(params || {}) });

        return new Promise((resolve, reject) => {
          let method = 'put';

          if (options.editingRequest.isUpload) {
            method = 'postUpload';
            params._method = 'put';
          }

          this.$api[method](`${options.editingRequest.endPoint}/${id}`, params)
            .then(response => {
              context.commit(baseName(options.editingRequest.mutationSuccess), response.data);
              resolve(response.data);
            })
            .catch(({ response }) => {
              context.commit(baseName(options.editingRequest.mutationFail), response.data);
              reject(response.data);
            });
        });
      },
    };
    mutations = { ...mutations,
      [baseName(options.editingRequest.mutationSuccess)](state, data) {
        state.editingRequest = {
          loading: false,
          success: true,
          fail: false,
          validation: {},
          response: data,
          params: {},
        };
      },
      [baseName(options.editingRequest.mutationFail)](state, data) {
        state.editingRequest = {
          loading: false,
          success: false,
          fail: true,
          validation: data.errors,
          response: {},
          params: {},
        };
      },
      SET_EDITING_REQUEST_PARAMS(state, params) {
        state.editingRequest.params = params;
      },
      SET_EDITING_REQUEST_LOADING(state, loading) {
        state.editingRequest.loading = loading
      },
      EDITING_REQUEST_RESET(state) {
        state.editingRequest = {
          loading: false,
          success: false,
          fail: false,
          validation: {},
          response: {},
          params: {},
        };
      }
    };
  }
  if (options.replaceRequest) {
    state = { ...state,
      replaceRequest: {
        loading: false,
        success: false,
        fail: false,
        validation: {},
        response: {},
        params: {},
      },
    };
    actions = { ...actions,
      [baseName(options.replaceRequest.actionReset)](context) {
        context.commit('REPLACE_REQUEST_RESET');
      },
      [baseName(options.replaceRequest.action)](context, { id, params }) {
        context.commit('SET_REPLACE_REQUEST_LOADING', true);
        context.commit('SET_REPLACE_REQUEST_PARAMS', { id, ...(params || {}) });

        return new Promise((resolve, reject) => {
          let method = 'patch';

          if (options.replaceRequest.isUpload) {
            method = 'postUpload';
            params._method = 'patch';
          }

          this.$api[method](`${options.replaceRequest.endPoint}/${id}`, params)
            .then(response => {
              context.commit(baseName(options.replaceRequest.mutationSuccess), response.data);
              resolve(response.data);
            })
            .catch(({ response }) => {
              context.commit(baseName(options.replaceRequest.mutationFail), response.data);
              reject(response.data);
            });
        });
      },
    };
    mutations = { ...mutations,
      [baseName(options.replaceRequest.mutationSuccess)](state, data) {
        state.replaceRequest = {
          loading: false,
          success: true,
          fail: false,
          validation: {},
          response: data,
          params: {},
        };
      },
      [baseName(options.replaceRequest.mutationFail)](state, data) {
        state.replaceRequest = {
          loading: false,
          success: false,
          fail: true,
          validation: data.errors,
          response: {},
          params: {},
        };
      },
      SET_REPLACE_REQUEST_PARAMS(state, params) {
        state.replaceRequest.params = params;
      },
      SET_REPLACE_REQUEST_LOADING(state, loading) {
        state.replaceRequest.loading = loading
      },
      REPLACE_REQUEST_RESET(state) {
        state.replaceRequest = {
          loading: false,
          success: false,
          fail: false,
          validation: {},
          response: {},
          params: {},
        };
      }
    };
  }
  if (options.deletionRequest) {
    state = { ...state,
      deletionRequest: {
        loading: false,
        success: false,
        fail: false,
        data: {},
        errors: {},
        params: {},
      }
    };
    actions = { ...actions,
      [baseName(options.deletionRequest.action)](context, { id }) {
        context.commit('SET_DELETION_REQUEST_LOADING', true);
        context.commit('SET_DELETION_REQUEST_PARAMS', { id });

        return new Promise((resolve, reject) => {
          this.$api.delete(`${options.deletionRequest.endPoint}/${id}`)
            .then(response => {
              context.commit(baseName(options.deletionRequest.mutationSuccess), response.data);
              resolve(response.data);
            })
            .catch(({ response }) => {
              context.commit(baseName(options.deletionRequest.mutationFail), response.data);
              reject(response.data);
            });
        });
      }
    };
    mutations = { ...mutations,
      [baseName(options.deletionRequest.mutationSuccess)](state, data) {
        state.deletionRequest = {
          loading: false,
          success: true,
          fail: false,
          data: data,
          errors: {},
          params: {},
        };
      },
      [baseName(options.deletionRequest.mutationFail)](state, data) {
        state.deletionRequest = {
          loading: false,
          success: false,
          fail: true,
          data: {},
          errors: data.errors,
          params: {},
        };
      },
      SET_DELETION_REQUEST_PARAMS(state, params) {
        state.deletionRequest.params = params;
      },
      SET_DELETION_REQUEST_LOADING(state, loading) {
        state.deletionRequest.loading = loading
      },
    };
  }

  return {
    state,
    getters,
    actions,
    mutations
  };
};
