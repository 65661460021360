import reducer from "../core/reducer";
import { baseName } from "../core/functions";

import {
  GET_QUOTE_BY_TOKEN,
  GET_QUOTE_BY_TOKEN_FAIL,
  GET_QUOTE_BY_TOKEN_SUCCESS,
  QUOTE_ACTIVATE_STRIPE,
  QUOTE_ACTIVATE_STRIPE_FAIL,
  QUOTE_ACTIVATE_STRIPE_SUCCESS,
  QUOTE_CONFIRM_RESERVATIONS,
  QUOTE_CONFIRM_RESERVATIONS_FAIL,
  QUOTE_CONFIRM_RESERVATIONS_SUCCESS,
  QUOTE_CREATE_RESERVATIONS,
  QUOTE_CREATE_RESERVATIONS_FAIL,
  QUOTE_CREATE_RESERVATIONS_SUCCESS,
  SET_QUOTE_FORM,
  RESET_QUOTE_FORM,
  QUOTE_REPLACE,
  QUOTE_REPLACE_FAIL,
  QUOTE_REPLACE_SUCCESS,
  QUOTES_RETRIEVE_SECOM_CARD_UI,
  QUOTES_RETRIEVE_SECOM_CARD_UI_SUCCESS,
  QUOTES_RETRIEVE_SECOM_CARD_UI_FAIL,
  QUOTES_STORE_SECOM_CARD,
  QUOTES_STORE_SECOM_CARD_SUCCESS,
  QUOTES_STORE_SECOM_CARD_FAIL,
  SUBMIT_QUOTE_REQUEST,
  SUBMIT_QUOTE_REQUEST_SUCCESS,
  SUBMIT_QUOTE_REQUEST_FAIL,
} from "./constants";

const setAction = baseName(SET_QUOTE_FORM);
const resetAction = baseName(RESET_QUOTE_FORM);

export default reducer({
  custom: {
    state: {
      form: {
        items: []
      },
    },
    actions: {
      [setAction](context, payload) {
        context.commit(`${setAction}_MUTATE`, payload);
      },
      [resetAction](context) {
        context.commit(`${resetAction}_MUTATE`);
      },
    },
    mutations: {
      [`${setAction}_MUTATE`](state, payload) {
        state.form = {
          ...state.form,
          ...payload
        }
      },
      [`${resetAction}_MUTATE`](state) {
        state.form = {}
      },
    }
  },
  actions: [
    {
      name: 'getByTokenRequest',
      action: GET_QUOTE_BY_TOKEN,
      endPoint: 'quotes/by-token/{token}',
      mutations: {
        fail: GET_QUOTE_BY_TOKEN_FAIL,
        success: GET_QUOTE_BY_TOKEN_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'createReservationsRequest',
      action: QUOTE_CREATE_RESERVATIONS,
      endPoint: 'quotes/reservations/create',
      mutations: {
        fail: QUOTE_CREATE_RESERVATIONS_FAIL,
        success: QUOTE_CREATE_RESERVATIONS_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'confirmReservationsRequest',
      action: QUOTE_CONFIRM_RESERVATIONS,
      endPoint: 'quotes/reservations/confirm',
      mutations: {
        fail: QUOTE_CONFIRM_RESERVATIONS_FAIL,
        success: QUOTE_CONFIRM_RESERVATIONS_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'activateStripeRequest',
      action: QUOTE_ACTIVATE_STRIPE,
      endPoint: 'quotes/stripe/activate',
      mutations: {
        fail: QUOTE_ACTIVATE_STRIPE_FAIL,
        success: QUOTE_ACTIVATE_STRIPE_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'replaceQuoteRequest',
      action: QUOTE_REPLACE,
      endPoint: 'quotes/replace-by-token',
      mutations: {
        fail: QUOTE_REPLACE_FAIL,
        success: QUOTE_REPLACE_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'getSecomCardUIRequest',
      action: QUOTES_RETRIEVE_SECOM_CARD_UI,
      endPoint: 'quotes/secom/card-ui',
      mutations: {
        fail: QUOTES_RETRIEVE_SECOM_CARD_UI_FAIL,
        success: QUOTES_RETRIEVE_SECOM_CARD_UI_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'storeSecomCardRequest',
      action: QUOTES_STORE_SECOM_CARD,
      endPoint: 'quotes/secom/card-store',
      mutations: {
        fail: QUOTES_STORE_SECOM_CARD_FAIL,
        success: QUOTES_STORE_SECOM_CARD_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'submitQuoteRequest',
      action: SUBMIT_QUOTE_REQUEST,
      endPoint: 'quotes/request',
      mutations: {
        fail: SUBMIT_QUOTE_REQUEST_FAIL,
        success: SUBMIT_QUOTE_REQUEST_SUCCESS,
      },
    },
  ]
});
