const namespace = 'checkout';

export const SET_CHECKOUT_FORM = `${namespace}/Form Set`;
export const RESET_CHECKOUT_FORM = `${namespace}/Form Reset`;

export const VALIDATE_EXTRAS = `${namespace}/Extras Validate`;
export const VALIDATE_EXTRAS_SUCCESS = `${namespace}/Extras Validate Success`;
export const VALIDATE_EXTRAS_FAIL = `${namespace}/Extras Validate Fail`;

export const VALIDATE_DETAILS = `${namespace}/Details Validate`;
export const VALIDATE_DETAILS_SUCCESS = `${namespace}/Details Validate Success`;
export const VALIDATE_DETAILS_FAIL = `${namespace}/Details Validate Fail`;

export const CREATE_RESERVATIONS = `${namespace}/Reservations Create`;
export const CREATE_RESERVATIONS_SUCCESS = `${namespace}/Reservations Create Success`;
export const CREATE_RESERVATIONS_FAIL = `${namespace}/Reservations Create Fail`;

export const CONFIRM_RESERVATIONS = `${namespace}/Reservations Confirm`;
export const CONFIRM_RESERVATIONS_SUCCESS = `${namespace}/Reservations Confirm Success`;
export const CONFIRM_RESERVATIONS_FAIL = `${namespace}/Reservations Confirm Fail`;

export const ACTIVATE_STRIPE = `${namespace}/Activate Stripe`;
export const ACTIVATE_STRIPE_SUCCESS = `${namespace}/Activate Stripe Success`;
export const ACTIVATE_STRIPE_FAIL = `${namespace}/Activate Stripe Fail`;

export const RETRIEVE_SECOM_CARD_UI = `${namespace}/Retrieve Secom Card UI`;
export const RETRIEVE_SECOM_CARD_UI_SUCCESS = `${namespace}/Retrieve Secom Card UI Success`;
export const RETRIEVE_SECOM_CARD_UI_FAIL = `${namespace}/Retrieve Secom Card UI Fail`;

export const STORE_SECOM_CARD = `${namespace}/Store Secom Card`;
export const STORE_SECOM_CARD_SUCCESS = `${namespace}/Store Secom Card Success`;
export const STORE_SECOM_CARD_FAIL = `${namespace}/Store Secom Card Fail`;

export const STORE_SECOM_CARD_WITH_PAYMENT_METHOD = `${namespace}/Store Secom Card With Payment Method`;
export const STORE_SECOM_CARD_WITH_PAYMENT_METHOD_SUCCESS = `${namespace}/Store Secom Card With Payment Method Success`;
export const STORE_SECOM_CARD_WITH_PAYMENT_METHOD_FAIL = `${namespace}/Store Secom Card With Payment Method Fail`;
