import reducer from "../core/reducer";

import {
  GET_GUESTS_PAGINATION,
  GET_GUESTS_PAGINATION_FAIL,
  GET_GUESTS_PAGINATION_SUCCESS,
  GET_GUESTS_SINGLE,
  GET_GUESTS_SINGLE_FAIL,
  GET_GUESTS_SINGLE_SUCCESS,
  CREATE_GUESTS,
  CREATE_GUESTS_FAIL,
  CREATE_GUESTS_SUCCESS,
  CREATE_GUESTS_RESET,
  UPDATE_GUESTS,
  UPDATE_GUESTS_FAIL,
  UPDATE_GUESTS_SUCCESS,
  UPDATE_GUESTS_RESET,
  REMOVE_GUESTS,
  REMOVE_GUESTS_FAIL,
  REMOVE_GUESTS_SUCCESS,
  GET_GUESTS_ALL,
  GET_GUESTS_ALL_FAIL,
  GET_GUESTS_ALL_SUCCESS,
  GET_GUESTS_GROUP,
  GET_GUESTS_GROUP_FAIL,
  GET_GUESTS_GROUP_SUCCESS,
  GET_GUESTS_FAMILY,
  GET_GUESTS_FAMILY_FAIL,
  GET_GUESTS_FAMILY_SUCCESS,
  VALIDATE_DGC,
  VALIDATE_DGC_SUCCESS,
  VALIDATE_DGC_FAIL
} from "./constants";

export default reducer({
  crud: {
    getPaginationRequest: {
      action: GET_GUESTS_PAGINATION,
      endPoint: "guests",
      mutationFail: GET_GUESTS_PAGINATION_FAIL,
      mutationSuccess: GET_GUESTS_PAGINATION_SUCCESS
    },
    getSingleRequest: {
      action: GET_GUESTS_SINGLE,
      endPoint: "guests",
      mutationFail: GET_GUESTS_SINGLE_FAIL,
      mutationSuccess: GET_GUESTS_SINGLE_SUCCESS
    },
    creationRequest: {
      isUpload: true,
      action: CREATE_GUESTS,
      actionReset: CREATE_GUESTS_RESET,
      endPoint: "guests",
      mutationFail: CREATE_GUESTS_FAIL,
      mutationSuccess: CREATE_GUESTS_SUCCESS
    },
    editingRequest: {
      isUpload: true,
      action: UPDATE_GUESTS,
      actionReset: UPDATE_GUESTS_RESET,
      endPoint: "guests",
      mutationFail: UPDATE_GUESTS_FAIL,
      mutationSuccess: UPDATE_GUESTS_SUCCESS
    },
    deletionRequest: {
      action: REMOVE_GUESTS,
      endPoint: "guests",
      mutationFail: REMOVE_GUESTS_FAIL,
      mutationSuccess: REMOVE_GUESTS_SUCCESS
    }
  },
  actions: [
    {
      name: 'getAllRequest',
      action: GET_GUESTS_ALL,
      endPoint: 'guests/all',
      mutations: {
        fail: GET_GUESTS_ALL_FAIL,
        success: GET_GUESTS_ALL_SUCCESS,
      },
    },
    {
      name: 'getGroupRequest',
      action: GET_GUESTS_GROUP,
      endPoint: 'guests/all',
      params: {
        group: true
      },
      mutations: {
        fail: GET_GUESTS_GROUP_FAIL,
        success: GET_GUESTS_GROUP_SUCCESS,
      },
    },
    {
      name: 'getFamilyRequest',
      action: GET_GUESTS_FAMILY,
      endPoint: 'guests/all',
      params: {
        family: true
      },
      mutations: {
        fail: GET_GUESTS_FAMILY_FAIL,
        success: GET_GUESTS_FAMILY_SUCCESS,
      },
    },
    {
      name: 'validateDgc',
      action: VALIDATE_DGC,
      endPoint: 'guests/validate-dgc',
      method: 'post',
      mutations: {
        fail: VALIDATE_DGC_FAIL,
        success: VALIDATE_DGC_SUCCESS
      }
    }
  ]
});
