const namespace = 'reservations';

export const GET_RESERVATIONS_PAGINATION = `${namespace}/Pagination Get`;
export const GET_RESERVATIONS_PAGINATION_SUCCESS = `${namespace}/Pagination Get Success`;
export const GET_RESERVATIONS_PAGINATION_FAIL = `${namespace}/Pagination Get Fail`;

export const ACTIVATE_PAYMENT = `${namespace}/Activate Payment`;
export const ACTIVATE_PAYMENT_SUCCESS = `${namespace}/Activate Payment Success`;
export const ACTIVATE_PAYMENT_FAIL = `${namespace}/Activate Payment Fail`;

export const CONFIRM_PAYMENT = `${namespace}/Confirm Payment`;
export const CONFIRM_PAYMENT_SUCCESS = `${namespace}/Confirm Payment Success`;
export const CONFIRM_PAYMENT_FAIL = `${namespace}/Confirm Payment Fail`;

export const RESERVATIONS_REPLACE = `${namespace}/Reservation Replace`;
export const RESERVATIONS_REPLACE_SUCCESS = `${namespace}/Reservation Replace Success`;
export const RESERVATIONS_REPLACE_FAIL = `${namespace}/Reservation Replace Fail`;
export const RESERVATIONS_REPLACE_RESET = `${namespace}/Reservation Replace Reset`;

export const RESERVATIONS_CANCEL = `${namespace}/Reservation Cancel`;
export const RESERVATIONS_CANCEL_SUCCESS = `${namespace}/Reservation Cancel Success`;
export const RESERVATIONS_CANCEL_FAIL = `${namespace}/Reservation Cancel Fail`;
export const RESERVATIONS_CANCEL_RESET = `${namespace}/Reservation Cancel Reset`;

export const LOCK_RESERVATION_SMART_LOCK = `${namespace}/Lock Smart Lock`;
export const LOCK_RESERVATION_SMART_LOCK_SUCCESS = `${namespace}/Lock Smart Lock Success`;
export const LOCK_RESERVATION_SMART_LOCK_FAIL = `${namespace}/Lock Smart Lock Fail`;
export const LOCK_RESERVATION_SMART_LOCK_RESET = `${namespace}/Lock Smart Lock Reset`;

export const UNLOCK_RESERVATION_SMART_LOCK = `${namespace}/Unlock Smart Lock`;
export const UNLOCK_RESERVATION_SMART_LOCK_SUCCESS = `${namespace}/Unlock Smart Lock Success`;
export const UNLOCK_RESERVATION_SMART_LOCK_FAIL = `${namespace}/Unlock Smart Lock Fail`;
export const UNLOCK_RESERVATION_SMART_LOCK_RESET = `${namespace}/Unlock Smart Lock Reset`;
