import reducer from "../core/reducer";

import {
  ACTIVATE_CITY_TAX_PAYMENT,
  ACTIVATE_CITY_TAX_PAYMENT_FAIL,
  ACTIVATE_CITY_TAX_PAYMENT_SUCCESS,
  CONFIRM_CITY_TAX_PAYMENT,
  CONFIRM_CITY_TAX_PAYMENT_FAIL,
  CONFIRM_CITY_TAX_PAYMENT_SUCCESS,
} from "./constants";

export default reducer({
  actions: [
    {
      method: 'post',
      name: 'activateCityTaxPaymentRequest',
      action: ACTIVATE_CITY_TAX_PAYMENT,
      endPoint: 'payments/activate-city-tax-payment',
      mutations: {
        fail: ACTIVATE_CITY_TAX_PAYMENT_FAIL,
        success: ACTIVATE_CITY_TAX_PAYMENT_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'confirmCityTaxPaymentRequest',
      action: CONFIRM_CITY_TAX_PAYMENT,
      endPoint: 'payments/confirm-city-tax-payment',
      mutations: {
        fail: CONFIRM_CITY_TAX_PAYMENT_FAIL,
        success: CONFIRM_CITY_TAX_PAYMENT_SUCCESS,
      },
    },
  ]
});
