import qs from 'qs';
import { objectToFormData } from '~/utils/helpers';

export default (ctx, inject) => {

  ctx.$axios.onRequest(config => {
    config.headers.common['Locale'] = ctx.app.i18n.locale;
  });

  ctx.$axios.onResponse(response => {
    // console.log(`[${response.status}] ${response.request.path}`);
    // console.log(response.data);
  });

  ctx.$axios.onError(err => {
    if(err.response.status === 404) {
      ctx.app.router.push('/404');
    }
    if(err.response.status === 500) {
      ctx.app.router.push('/sorry');
    }
    if(err.response.status === 400) {
      alert(err.response.data.message || 'Error. Contact the property.')
    }
  });

  const api = {
    query(resource, params, config) {
      return ctx.$axios.$get(resource, {
          params: params,
          paramsSerializer: function(params) {
            return qs.stringify(params, {
              arrayFormat: 'indices'
            })
          },
        }, config);
    },
    get(resource, config) {
      return ctx.$axios.$get(resource, config);
    },
    post(resource, params, config) {
      return ctx.$axios.$post(resource, params, config);
    },
    put(resource, params, config) {
      return ctx.$axios.$put(resource, params, config);
    },
    patch(resource, params, config) {
      return ctx.$axios.$patch(resource, params, config);
    },
    delete(resource, config) {
      return ctx.$axios.$delete(resource, config);
    },
    postUpload(resource, params, config) {
      return ctx.$axios.$post(resource, objectToFormData(params), config);
    },
  };

  inject('api', api);
};
