const namespace = 'booking-networks';

export const GET_BOOKING_NETWORKS = `${namespace}/Booking Networks Get`;
export const GET_BOOKING_NETWORKS_SUCCESS = `${namespace}/Booking Networks Get Success`;
export const GET_BOOKING_NETWORKS_FAIL = `${namespace}/Booking Networks Get Fail`;

export const GET_BOOKING_NETWORK_PROMOTIONS = `${namespace}/Booking Network Promotions Get`;
export const GET_BOOKING_NETWORK_PROMOTIONS_SUCCESS = `${namespace}/Booking Networks Promotions Success`;
export const GET_BOOKING_NETWORK_PROMOTIONS_FAIL = `${namespace}/Booking Networks Promotions Fail`;

export const GET_BOOKING_NETWORK_BLOCKS = `${namespace}/Booking Network Blocks Get`;
export const GET_BOOKING_NETWORK_BLOCKS_SUCCESS = `${namespace}/Booking Network Blocks Get Success`;
export const GET_BOOKING_NETWORK_BLOCKS_FAIL = `${namespace}/Booking Network Blocks Get Fail`;
