const namespace = 'properties';

export const GET_PROPERTIES_PAGINATION = `${namespace}/Pagination Get`;
export const GET_PROPERTIES_PAGINATION_SUCCESS = `${namespace}/Pagination Get Success`;
export const GET_PROPERTIES_PAGINATION_FAIL = `${namespace}/Pagination Get Fail`;

export const GET_PROPERTIES_SINGLE = `${namespace}/Single Get`;
export const GET_PROPERTIES_SINGLE_SUCCESS = `${namespace}/Single Get Success`;
export const GET_PROPERTIES_SINGLE_FAIL = `${namespace}/Single Get Fail`;

export const GET_PROPERTIES_AVG_PRICE = `${namespace}/AVG Price Get`;
export const GET_PROPERTIES_AVG_PRICE_SUCCESS = `${namespace}/AVG Price Get Success`;
export const GET_PROPERTIES_AVG_PRICE_FAIL = `${namespace}/AVG Price Get Fail`;

export const GET_PROPERTIES_SINGLE_COMBINATIONS = `${namespace}/Single Combinations Get`;
export const GET_PROPERTIES_SINGLE_COMBINATIONS_SUCCESS = `${namespace}/Single Combinations Get Success`;
export const GET_PROPERTIES_SINGLE_COMBINATIONS_FAIL = `${namespace}/Single Combinations Get Fail`;