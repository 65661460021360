import reducer from "../core/reducer";

import {
  GET_AMENITIES,
  GET_AMENITIES_FAIL,
  GET_AMENITIES_SUCCESS
} from "./constants";

export default reducer({
  actions: [
    {
      name: 'getAmenitiesRequest',
      action: GET_AMENITIES,
      endPoint: 'amenities',
      mutations: {
        fail: GET_AMENITIES_FAIL,
        success: GET_AMENITIES_SUCCESS,
      },
    }
  ]
});
