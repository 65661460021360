import reducer from "../core/reducer";

import {
  GET_BOOKING_NETWORKS,
  GET_BOOKING_NETWORKS_FAIL,
  GET_BOOKING_NETWORKS_SUCCESS,
  GET_BOOKING_NETWORK_PROMOTIONS,
  GET_BOOKING_NETWORK_PROMOTIONS_FAIL,
  GET_BOOKING_NETWORK_PROMOTIONS_SUCCESS,
  GET_BOOKING_NETWORK_BLOCKS,
  GET_BOOKING_NETWORK_BLOCKS_SUCCESS,
  GET_BOOKING_NETWORK_BLOCKS_FAIL
} from "./constants";

export default reducer({
  actions: [
    {
      name: 'getBookingNetworksRequest',
      action: GET_BOOKING_NETWORKS,
      endPoint: 'network',
      mutations: {
        fail: GET_BOOKING_NETWORKS_FAIL,
        success: GET_BOOKING_NETWORKS_SUCCESS,
      },
    },
    {
      name: 'getBookingNetworkPromotionsRequest',
      action: GET_BOOKING_NETWORK_PROMOTIONS,
      endPoint: 'promotions',
      mutations: {
        fail: GET_BOOKING_NETWORK_PROMOTIONS_FAIL,
        success: GET_BOOKING_NETWORK_PROMOTIONS_SUCCESS,
      },
    },
    {
      name: 'getBookingNetworkBlocksRequest',
      action: GET_BOOKING_NETWORK_BLOCKS,
      endPoint: 'blocks',
      mutations: {
        fail: GET_BOOKING_NETWORK_BLOCKS_FAIL,
        success: GET_BOOKING_NETWORK_BLOCKS_SUCCESS,
      },
    }
  ]
});
