import reducer from "../core/reducer";
import { baseName } from "../core/functions";

import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  EMPTY_CART,
} from "./constants";

const addAction = baseName(ADD_TO_CART);
const emptyAction = baseName(EMPTY_CART);
const removeAction = baseName(REMOVE_FROM_CART);

export default reducer({
  custom: {
    state: {
      items: []
    },
    actions: {
      [addAction](context, payload) {
        context.commit(`${addAction}_MUTATE`, payload);
      },
      [removeAction](context, payload) {
        context.commit(`${removeAction}_MUTATE`, payload);
      },
      [emptyAction](context) {
        context.commit(`${emptyAction}_MUTATE`);
      },
    },
    mutations: {
      [`${addAction}_MUTATE`](state, payload) {
        let items = state.items.filter(item => {
          return item.id === payload.id && item.guests === payload.guests
        });

        if (items.length > 0) {
          items[0].quantity++;
          return;
        }

        state.items = [
          ...state.items,
          payload
        ];
      },
      [`${removeAction}_MUTATE`](state, id) {
        state.items = [ ...state.items.filter(item => item.id !== id) ];
      },
      [`${emptyAction}_MUTATE`](state) {
        state.items = [];
      },
    }
  },
});
