import { baseName } from './functions';

export default (options) => {
  let getters = {};

  let state = {
    [options.name]: {
      loading: false,
      success: false,
      fail: false,
      data: [],
      errors: {},
      params: {}
    }
  };

  let actions = {
    [baseName(options.action)](context, params) {
      context.commit(`${baseName(options.action)}_SET_LOADING`, true);

      let endPoint = options.endPoint;

      return new Promise((resolve, reject) => {
        const method = options.method || 'query';

        if (typeof params === 'object' && params) {
          for (let param in params) {
            endPoint = endPoint.replace(`{${param}}`, params[param]);
          }
        }

        params = params ? params : {};

        if (options.params) {
          params = { ...params, ...options.params};
        }

        context.commit(`${baseName(options.action)}_SET_PARAMS`, params);

        this.$api[method](endPoint, params, options.config)
          .then(response => {
            context.commit(baseName(options.mutations.success), response.data);
            resolve(response.data);
          })
          .catch(({ response }) => {
            context.commit(baseName(options.mutations.fail), response.data);
            reject(response.data);
          });
      });
    }
  };

  if (options.actionReset) {
    actions = {
      ...actions,
      [baseName(options.actionReset)](context) {
        context.commit(`${baseName(options.action)}_RESET`);
      },
    }
  }

  let mutations = {
    [baseName(options.mutations.success)](state, data) {
      state[options.name] = {
        loading: false,
        success: true,
        fail: false,
        data: data,
        errors: {},
        params: {}
      };

      if (typeof options.mutations.successCallback === 'function') {
        options.mutations.successCallback(state, data);
      }
    },
    [baseName(options.mutations.fail)](state, data) {
      state[options.name] = {
        loading: false,
        success: false,
        fail: true,
        data: [],
        errors: data.errors,
        params: {}
      };

      if (typeof options.mutations.errorCallback === 'function') {
        options.mutations.errorCallback(state, data.errors);
      }
    },
    [`${baseName(options.action)}_SET_PARAMS`](state, params) {
      state[options.name].params = params;
    },
    [`${baseName(options.action)}_SET_LOADING`](state, loading) {
      state[options.name].loading = loading
    },
    [`${baseName(options.action)}_RESET`](state) {
      state[options.name] = {
        loading: false,
        success: false,
        fail: false,
        data: [],
        errors: {},
        params: {}
      };
    },
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
};
